import { Flex, Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import VotingRow from './VotingRow';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const VotingRowContainer = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    
    const onViewAllClick = (shortcode) => {
        navigate(`/vote/${shortcode}`);
    }

    useEffect(() => {
        setLoading(true);
        async function fetchData() {
            try {
                const categoryData = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/category`);
                setCategories(categoryData.data);
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
        fetchData();
    }, []);

    return (
        <>
            {
                loading ? (
                    <Flex justifyContent="center" alignItems="center" height="90vh">
                        <Spinner size="xl" />
                    </Flex>
                ) : (
                    categories.map((awardCategory) => {
                        return (
                          <VotingRow categoryId={awardCategory.id} awardCategory={awardCategory.name} shortcode={awardCategory.shortcode} onViewAllClick={onViewAllClick} />
                        )
                      })
                )

            }
        </>
    )
}

export default VotingRowContainer