
import React from 'react';
import { Box, Button, Container, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { categoryColors } from '../../common/commonData';
import Header from '../shared/Header';
import Banner from '../shared/Banner';
import { Link } from 'react-router-dom';
import Footer from './Footer';

const NotFound = () => {
    return (
        <>
            <Header />
            <Container marginY={20} minH='40vh' maxW="container.xl" centerContent>
                <Flex marginY={10} justifyContent='center' alignItems='center' className='about'>
                    <Flex alignItems='center' direction="column" p="4">
                        <Heading as="h1" size="lg" mb="2">
                            Page not found
                        </Heading>
                        <Text fontSize="lg" color="gray.500" mb="2">
                            The page you are looking for does not exist.
                        </Text>
                        <Link to='/'>
                            <Button
                                colorScheme={categoryColors['General']}
                                variant="solid"
                                size="lg"
                                width="100px"
                                borderRadius="md"
                            >
                                Go Home
                            </Button>
                        </Link>
                    </Flex>
                </Flex>
            </Container>
            <Footer />
        </>
    );
};

export default NotFound;