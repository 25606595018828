import { Box, Button, Container, Flex, HStack, Heading,  Image, Spinner, Text, useToast, } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import VoterInfo from './VoterInfo'
import { FaLinkedin } from "react-icons/fa";
import Header from '../shared/Header'
import Footer from '../shared/Footer'
import axios from 'axios'
import VotingRowContainer from './VotingRowContainer'


const Profile = () => {
  const toast = useToast();
  const { category, person } = useParams();
  const [personData, setPersonData] = React.useState(null);
  const [nominationData, setNominationData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [isVoterInfoOpen, setVoterInfoOpen] = useState(false);
  const [hasAlreadyVoted, setHasAlreadyVoted] = React.useState(false);

  const navigate = useNavigate()
  const handleVoteBtn = () => {
    if (hasAlreadyVoted) {
      toast({
        title: "You have already voted for this category",
        status: "error",
        duration: 3000,
        isClosable: true,
      })
      return;
    }
    setVoterInfoOpen(true);
  };

  const closeVoterInfo = () => {
    setVoterInfoOpen(false);
  }

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      try {
        const data = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/nomination/category-person`, {
          categoryShortcode: category,
          personShortcode: person
        });
        setPersonData(data.data.personData);
        setNominationData(data.data.nomination);
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.status === 404) {
            navigate('/404');
          }
          toast({
            title: error.response.data.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          })
        }
        console.log(error);
        setLoading(false);
      }
    }
    fetchData();
  }, [category, person]
  )

  useEffect(() => {
    const votes = JSON.parse(localStorage.getItem('votes'));
    if (votes && votes.includes(nominationData?.categoryId)) {
      setHasAlreadyVoted(true);
    } else {
      setHasAlreadyVoted(false);
    }
  }, [nominationData])

  return (
    <>
      <Header />
      {
        loading ? (
          <Container maxW='container.xl' centerContent>
            <Spinner size='xl' />
          </Container>
        ) : (
          <>
            <Container minW='80vw'>
              <Flex
                display={{ base: 'none', md: 'flex' }}
                justifyContent='center'
                marginTop='20px'
                gap={4}
                borderWidth={0.5}
                borderRadius='md'
                padding={10}
              >
                <Box
                  backgroundRepeat='no-repeat'
                  backgroundImage={require('../../assets/images/card-bg.png')} position="relative"
                  display='flex'
                  justifyContent='center'
                  alignItems='end'
                  minWidth="290px"
                  minHeight="200px"
                  maxWidth="290px"
                  maxHeight="200px"
                  textAlign="center"
                >
                  <Image
                    src={personData?.photo ? personData?.photo : require('../../assets/images/placeholder.jpg')}
                    alt={personData?.firstName + " " + personData?.lastName}
                    objectFit="cover"
                    objectPosition="bottom"
                    width="50%"
                    height="80%"
                    borderRadius="md"
                    borderWidth={1}
                    border={'solid white'}
                  />
                </Box>
                <Flex
                  direction='column'
                  justifyContent='flex-start'
                  gap={4}
                  height="200px"
                  textAlign="left"
                  width='100%'
                >
                  <Flex
                    justifyContent='space-between'
                    textAlign="center"
                    width='100%'
                  >
                    <Box>
                      <HStack>
                        <Text fontSize='2xl' fontWeight='bold' textAlign='left' paddingLeft='30px'>
                          {personData?.firstName + " " + personData?.lastName}
                        </Text>
                        <FaLinkedin color='#0A66C2' cursor='pointer' onClick={() => window.open(personData?.linkedin, "_blank")} />
                      </HStack>
                      <Text textAlign='left' paddingLeft='30px' color='gray'> {personData?.designation + ", " + personData?.company}</Text>
                    </Box>
                    <Box>
                      <Button
                        colorScheme='blue'
                        px='30px'
                        onClick={handleVoteBtn}
                      > Vote</Button>
                    </Box>
                  </Flex>
                  <Box>
                    <Text paddingLeft='30px'>
                      {personData?.about}
                    </Text>
                  </Box>
                </Flex>

              </Flex>

              <Flex
                display={{ base: 'flex', md: 'none' }}
                direction='column'
                justifyContent='center'
                marginTop='20px'
                gap={4}
              >
                {/* <Image width='100%' src='images/dummy.png' /> */}
                <Box
                  alignSelf='center'
                  backgroundRepeat='no-repeat'
                  backgroundImage={require('../../assets/images/card-bg.png')}
                  position="relative"
                  display='flex'
                  justifyContent='center'
                  alignItems='end'
                  minWidth="290px"
                  minHeight="200px"
                  maxWidth="290px"
                  maxHeight="200px"
                  textAlign="center"
                  width='100%'
                >
                  <Image
                    src={personData?.photo ? personData?.photo : require('../../assets/images/dummy.png')}
                    alt={personData?.firstName + " " + personData?.lastName}
                    objectFit="cover"
                    objectPosition="bottom"
                    width="50%"
                    height="80%"
                    borderRadius="md"
                    borderWidth={1}
                    border={'solid white'}
                  />
                </Box>
                <Box>
                  <Button
                    colorScheme='blue'
                    px='42%'
                    marginTop='20px'
                    onClick={handleVoteBtn}
                  >Vote</Button>
                </Box>
                <Box >
                  <HStack>
                    <Text paddingLeft='10px' fontSize='4xl' fontWeight='bold' textAlign='left'>{personData?.firstName + " " + personData?.lastName}</Text>
                    <FaLinkedin color='#0A66C2' cursor='pointer' onClick={() => window.open(personData?.linkedin, "_blank")} />
                  </HStack>
                  <Text paddingLeft='10px' color='gray' textAlign='left'>{personData?.designation + ", " + personData?.company}</Text>
                </Box>
                <Box width='100%' >
                  <Text padding='10px' textAlign='justify'>
                    {personData?.about}
                  </Text>
                </Box>
              </Flex>
              {
                isVoterInfoOpen &&
                <VoterInfo isOpen={isVoterInfoOpen} onClose={closeVoterInfo} categoryId={nominationData?.categoryId} nominationId={nominationData?.id} />
              }
            </Container>

            <Box marginTop={10} marginInline={{ base: 0, md: 75 }} marginBottom={20}>
              <Heading as='h2' size={{ base: 'sm', md: 'md' }} textAlign='center' my='4'>
                Nominees
              </Heading>
              {
                loading ? (
                  <Flex justifyContent="center" alignItems="center" height="90vh">
                    <Spinner size="xl" />
                  </Flex>
                ) :
                  <VotingRowContainer />
              }
            </Box>
          </>)
      }
      <Footer />
    </>
  )
}

export default Profile

