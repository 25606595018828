import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react'
import React from 'react'
import { categoryColors } from '../../common/commonData'

const AwardCategoryCard = ({awardCategory}) => {
  return (
    <Flex
        justifyContent='space-evenly'
        alignItems='flex-start'
        direction='column'
        borderRadius='lg'
        boxShadow='md'
        overflow='hidden'
        width={{base: '90vw', lg: '584px'}}
        minWidth={{base: '90vw', lg: '584px'}}
        minHeight='350px'
        maxWidth='584px'
        maxHeight='350px'
        border={'solid 1px #E2E8F0'}
        padding='4'
    >   
    <Box backgroundColor={categoryColors[awardCategory.name].bg} padding={3} borderRadius='md'>

        <Image src={awardCategory.icon}  alt='about' />
    </Box>
        <Heading as='h3' size='md' fontSize='24px' mb='2'>{awardCategory.name}</Heading>
        <Text wordBreak='break-word' textAlign='left' fontSize='sm' color='gray.500' mb='2'>
            {awardCategory.description}
        </Text>
    </Flex>
  )
}

export default AwardCategoryCard