import React, { useEffect, useRef, useState } from "react";
import Banner from "../shared/Banner";
import {
  Button,
  Container,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Text,
  Textarea,
  Select,
  Tag,
  TagLabel,
  TagCloseButton,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  FormErrorMessage,
  FormHelperText,
  useToast,
  Spinner
} from "@chakra-ui/react";
import { awardCategories, districts, states } from "../../common/commonData";
import Header from "../shared/Header";
import { Link } from "react-router-dom";
import InformationDialog from "../shared/InformationDialog";
import Footer from "../shared/Footer";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

const Nomination = () => {
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();
  const toast = useToast();
  const infoRef = useRef(null);

  const idInputRef = useRef(null);
  const photoInputRef = useRef(null);

  const [formData, setFormData] = useState({
    email: user ? user.email : ""
  });
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [idProofFile, setIdProofFile] = useState(null);
  const [photoFile, setPhotoFile] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(
    () => {
      if (submitted) {
        return;
      }
      const checkNominationExists = async () => {
        setLoading(true);
        if (!isAuthenticated) {
          return;
        }
        try {
          const tokenData = await JSON.parse(localStorage.getItem(`@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::@@user@@`));
          const token = tokenData.id_token;
          const personData = await axios.get(
            `${process.env.REACT_APP_SERVER_BASE_URL}/api/person`,
            {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            }
          )
          const person = personData.data;
          if (person) {
            if (!submitted) {
              setSubmitted(true);
            }
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.error("GET request failed:", error);
        }
      }
      async function fetchData() {
        if (!isAuthenticated) {
          loginWithRedirect().then(async () => {
            await checkNominationExists();
          }
          );
        } else {
          await checkNominationExists();
        }
      }
      fetchData();
    }, [isAuthenticated, loginWithRedirect, submitted, toast]
  )


  const validateLink = (inputValue) => {
    const urlRegex = /^(?:(?:(?:https?|ftp):)?\/\/)?(?:www\.)?[^\s.]+\.[^\s]{2,}$/i;

    const isValid = urlRegex.test(inputValue);

    return isValid;
  };

  const handleStateChange = async (state) => {
    setSelectedState(state);
    setFormData({ ...formData, state });
    // Reset selected city when the state changes
    setSelectedCity("");
  };

  const validateForm = () => {
    // Implement your form validation logic here
    const errors = {};
    // Example: Check if required fields are not empty
    if (
      !formData.firstName ||
        !formData.lastName ||
        !formData.gender ||
        !formData.state ||
        !formData.city ||
        !formData.email ||
        !validatePhone(formData.phone) ||
        !formData.about ||
        !validateLink(formData.linkedin) ||
        !formData.category1 ||
        !formData.category1Reason ||
        !formData.company ||
        !formData.designation ||
        !formData.experience
    ) {
      errors.requiredFields = "Please fill in all required fields.";
      toast({
        title: "Error",
        description: "Please fill in all required fields correctly.",
        status: "error",
        duration: 3000,
        isClosable: true
      });
    } else if (!photoFile) {
      errors.photo = "Please upload your photo.";
      toast({
        title: "Error",
        description: "Please upload your photo.",
        status: "error",
        duration: 3000,
        isClosable: true
      });
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };


  const handleFileChange = (e, setFileState) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // Check file size (1MB limit)
      if (selectedFile.size <= 10 * 1024 * 1024) {
        setFileState(selectedFile);
        // eslint-disable-next-line no-console
      } else {
        setFormErrors({ ...formErrors, fileSize: "File size should not exceed 1MB." });
        toast({
          title: "Error",
          description: "File size should not exceed 1MB.",
          status: "error",
          duration: 3000,
          isClosable: true
        });
        e.target.value = null;
      }
    }
  };

  const handleIdProofFileChange = (e) => {
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
    if (!allowedTypes.includes(e.target.files[0].type)) {
      setFormErrors({ ...formErrors, fileType: "Only JPG/PNG/PDF file types are allowed." });
      toast({
        title: "Error",
        description: "Only JPG/PNG/PDF file types are allowed.",
        status: "error",
        duration: 3000,
        isClosable: true
      });
      e.target.value = null;
      return;
    }
    handleFileChange(e, setIdProofFile);
  };

  const handlePhotoFileChange = (e) => {
    const allowedTypes = ["image/jpeg", "image/png"];
    if (!allowedTypes.includes(e.target.files[0].type)) {
      setFormErrors({ ...formErrors, fileType: "Only JPG/PNG file types are allowed." });
      toast({
        title: "Error",
        description: "Only JPG/PNG file types are allowed.",
        status: "error",
        duration: 3000,
        isClosable: true
      });
      e.target.value = null;
      return;
    }
    handleFileChange(e, setPhotoFile);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^[6-9]\d{9}$/;
    return phoneRegex.test(phone);
  };

  const handleSubmit = async (event) => {
    try {
      setLoading(true);
      event.preventDefault();

      const tokenData = await JSON.parse(localStorage.getItem(`@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::@@user@@`));
      const token = tokenData.id_token;

      if (!validateForm()) {
        setLoading(false);
        return;
      }

      const photoData = await axios.post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/file/presigned-url`,
        {
          fileName: photoFile.name,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      )
      const photoPresignedUrl = photoData.data.url;
      const photoKey = photoData.data.key;

      await axios.put(
        photoPresignedUrl,
        photoFile,
        {
          headers: {
            'Content-Type': photoFile.type
          }
        }
      );


      const person = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        gender: formData.gender.toUpperCase(),
        state: formData.state,
        city: formData.city,
        email: formData.email,
        phone: formData.phone,
        about: formData.about,
        linkedin: formData.linkedin,
        github: formData.github ? formData.github : null,
        portfolio: formData.portfolio ? formData.portfolio : null,
        twitter: formData.twitter ? formData.twitter : null,
        youtube: formData.youtube ? formData.youtube : null,
        idProof: null,
        photo: photoKey,
        createdAt: new Date(),
        company: formData.company,
        designation: formData.designation,
        experience: +formData.experience,
      }
      const nominations = [];
      nominations.push({
        category: formData.category1,
        reason: formData.category1Reason,
        createdAt: new Date(),
        approval: false
      });
      if (formData.category2) {
        nominations.push({
          category: formData.category2,
          reason: formData.category2Reason,
          createdAt: new Date(),
          approval: false
        });
      }

      const data = {
        person,
        nominations
      }


      const personCreated = await axios.post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/person`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      )
      if (personCreated.status === 200 || personCreated.status === 201 || personCreated.status === 204) {
        setSubmitted(true);
        toast({
          title: "Success",
          description: "Your application has been submitted successfully.",
          status: "success",
          duration: 3000,
          isClosable: true
        });
      } else {
        const personCreatedData = personCreated?.data;
        toast({
          title: "Error",
          description: personCreatedData?.message ? personCreatedData.message : "Something went wrong. Please try again later.",
          status: "error",
          duration: 3000,
          isClosable: true
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("POST request failed:", error);
      toast({
        title: "Error",
        description: error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong. Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true
      });
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "city") {
      setSelectedCity(value);
    }
    if (name === "state") {
      setSelectedState(value);
      // Reset selected city when the state changes
      setSelectedCity("");
    }
    setFormData({ ...formData, [name]: value });

    // Clear the error message when the user starts typing in the field
    setFormErrors({ ...formErrors, [name]: null });
  };
  return (
    <div>
      <Header />
      {loading && <Container centerContent height='80vh'>
        <Spinner size="xl" />
      </Container>
      }
      {!loading && isAuthenticated && <>
        <Banner title={"Award Nomination Form"} color={"white"} />
        {
          submitted ? (
            <>
              <Alert
                status='success'
                variant='subtle'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
                textAlign='center'
                height={{ base: '50vh', md: '70vh' }}
              >
                <AlertIcon boxSize='40px' mr={0} />
                <AlertTitle mt={4} mb={1} fontSize='lg'>
                  Application submitted!
                </AlertTitle>
                <AlertDescription maxWidth='sm'>
                  Thanks for submitting your application. Our team will get back to you soon.
                </AlertDescription>
                <Link to='/'>
                  <Button
                    colorScheme='green'
                    variant='outline'
                    width='fit-content'
                    mt={5}
                  >
                    Navigate to Home
                  </Button>
                </Link>
              </Alert>
            </>
          ) : (
            <Container minW={"80vw"} marginBottom={20} marginTop={5}>
              <form onSubmit={handleSubmit}>
                <Text align="left" fontSize="2xl" marginY={5} color="#3085FB">
                  Personal Details
                </Text>
                <Grid
                  templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
                  gap={10}
                  rowGap={3}
                >
                  <GridItem colSpan={{ base: 3, md: 1 }}>
                    <FormControl isRequired>
                      <FormLabel>First Name</FormLabel>
                      <Input
                        value={formData.firstName}
                        onChange={handleChange}
                        name="firstName"
                        type="text"
                        placeholder="First Name"
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={{ base: 3, md: 1 }}>
                    <FormControl isRequired>
                      <FormLabel>Last Name</FormLabel>
                      <Input
                        value={formData.lastName}
                        onChange={handleChange}
                        name="lastName"
                        type="text"
                        placeholder="Last Name"
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={{ base: 3, md: 1 }}>
                    <FormControl isRequired>
                      <FormLabel>Gender</FormLabel>
                      <Select
                        value={formData.gender}
                        onChange={handleChange}
                        name="gender"
                        placeholder="Gender"
                      >
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="non-binary">Non Binary</option>
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={{ base: 3, md: 1 }}>
                    <FormControl isRequired>
                      <FormLabel>State</FormLabel>
                      <Select
                        name="state"
                        placeholder="Select State"
                        value={selectedState}
                        onChange={(e) => handleStateChange(e.target.value)}
                      >
                        {states.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={{ base: 3, md: 1 }}>
                    <FormControl isRequired>
                      <FormLabel>City</FormLabel>
                      <Select
                        onChange={handleChange}
                        name="city"
                        placeholder="Select City"
                        value={selectedCity}
                        isDisabled={!selectedState}
                      >
                        {districts[selectedState] &&
                          districts[selectedState].map((city) => (
                            <option key={city} value={city}>
                              {city}
                            </option>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={{ base: 3, md: 1 }}>
                    <FormControl isRequired isInvalid={formData.phone ? !validatePhone(formData.phone) : false}>
                      <FormLabel>Phone Number</FormLabel>
                      <Input
                        value={formData.phone}
                        onChange={handleChange}
                        name="phone"
                        type="tel"
                        placeholder="Phone Number"
                      />
                      <FormErrorMessage>Please provide valid phone number</FormErrorMessage>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={{ base: 3, md: 1 }}>
                    <FormControl isRequired>
                      <FormLabel>Email</FormLabel>
                      <Input
                        value={user.email}
                        name="email"
                        readOnly
                        disabled
                        type="email"
                        placeholder="Email"
                      />
                      <FormErrorMessage>Please provide valid email</FormErrorMessage>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <FormControl isRequired>
                      <FormLabel>About You</FormLabel>
                      <Textarea
                        value={formData.about}
                        name="about"
                        placeholder="About You"
                        resize={"none"}
                        rows={5}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <FormControl isRequired isInvalid={formData.linkedin ? !validateLink(formData.linkedin) : false}>
                      <FormLabel>Linkedin</FormLabel>
                      <Input
                        value={formData.linkedin}
                        onChange={handleChange}
                        name="linkedin"
                        type="text"
                        placeholder="Linkedin"
                      />
                      <FormErrorMessage>Please provide valid URL</FormErrorMessage>
                    </FormControl>
                  </GridItem>
                </Grid>
                <Text align="left" fontSize="2xl" marginY={5} color="#3085FB">
                  Work Experience
                </Text>
                <Grid templateColumns="repeat(3, 1fr)" gap={10} rowGap={3}>
                  <GridItem colSpan={{ base: 3, md: 1 }}>
                    <FormControl isRequired>
                      <FormLabel>Company</FormLabel>
                      <Input
                        value={formData.company}
                        onChange={handleChange}
                        name="company"
                        type="text"
                        placeholder="Company"
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={{ base: 3, md: 1 }}>
                    <FormControl isRequired>
                      <FormLabel>Designation</FormLabel>
                      <Input
                        value={formData.designation}
                        onChange={handleChange}
                        name="designation"
                        type="text"
                        placeholder="Designation"
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={{ base: 3, md: 1 }}>
                    <FormControl isRequired>
                      <FormLabel>Years of Experience</FormLabel>
                      <Input
                        value={formData.experience}
                        onChange={handleChange}
                        name="experience"
                        type="number"
                        placeholder="Years of Experience"
                      />
                    </FormControl>
                  </GridItem>
                </Grid>
                <Text align="left" fontSize="2xl" marginY={5} color="#3085FB">
                  Nomination Categories
                </Text>
                <Grid templateColumns="repeat(2, 1fr)" gap={10} rowGap={3}>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <FormControl isRequired>
                      <FormLabel>Award Category 1</FormLabel>
                      <Select
                        value={formData.category1}
                        onChange={handleChange}
                        name="category1"
                        placeholder="Select Award Category"
                      >
                        {awardCategories
                          .filter((category) => {
                            if (category.femaleSpecific) {
                              return formData.gender === "female";
                            } else {
                              return true;
                            }
                          })
                          .map((category) => (
                            <option key={category.name} value={category.shortcode}>
                              {category.name}
                            </option>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <FormControl isRequired>
                      <FormLabel>Reason to Nominate</FormLabel>
                      <Textarea
                        value={formData.category1Reason}
                        name="category1Reason"
                        placeholder="Reason for Nomination"
                        resize={"none"}
                        onChange={handleChange}
                        rows={5}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <FormControl>
                      <Text textAlign='left' color={'gray'} fontSize='sm'>If you want you can nominate for one more category</Text>
                      <FormLabel>Award Category 2</FormLabel>
                      <Select
                        value={formData.category2}
                        onChange={handleChange}
                        name="category2"
                        placeholder="Select Award Category"
                      >
                        {awardCategories
                          .filter((category) => {
                            if (category.shortcode === formData.category1) {
                              return false;
                            }
                            if (category.femaleSpecific) {
                              return formData.gender === "female";
                            } else {
                              return true;
                            }
                          })
                          .map((category) => (
                            <option key={category.name} value={category.shortcode}>
                              {category.name}
                            </option>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <FormControl isRequired={formData.category2}>
                      <FormLabel>Reason to Nominate</FormLabel>
                      <Textarea
                        value={formData.category2Reason}
                        name="category2Reason"
                        placeholder="Reason for Nomination"
                        resize={"none"}
                        onChange={handleChange}
                        rows={5}
                      />
                    </FormControl>
                  </GridItem>
                </Grid>
                <Text align="left" fontSize="2xl" marginY={5} color="#3085FB">
                  Documents
                </Text>
                <Grid templateColumns="repeat(3, 1fr)" gap={10} rowGap={3}>
                  <GridItem alignContent={'start'} colSpan={{ base: 3, md: 1 }}>
                    <FormControl isRequired>
                      <FormLabel>Upload Your Photo</FormLabel>
                      <input
                        type="file"
                        ref={photoInputRef}
                        style={{ display: "none" }}
                        accept=".jpg, .png"
                        onChange={handlePhotoFileChange}
                      />
                      {
                        photoFile ? (
                          <Tag color="green.500" size='lg' fontSize="lg" mb="2">
                            <TagLabel>
                              {photoFile.name}
                            </TagLabel>
                            <TagCloseButton onClick={() => {
                              setPhotoFile(null);
                              photoInputRef.current.value = null;
                            }} />
                          </Tag>
                        ) :
                          <>
                            <Button onClick={
                              () => {
                                photoInputRef.current.click();
                              }
                            } w={"100%"} colorScheme="messenger">
                              Upload
                            </Button>
                            <FormHelperText>
                              Please upload your photo of size less than 10MB.
                            </FormHelperText>
                          </>
                      }
                    </FormControl>
                  </GridItem>
                </Grid>
                <Grid
                  marginY={7}
                  templateColumns="repeat(3, 1fr)"
                  gap={10}
                  rowGap={3}
                >
                  <GridItem gridColumn={2}>
                    <Button w="fit-content" type="submit" colorScheme="green">
                      Submit
                    </Button>
                  </GridItem>
                </Grid>
              </form>
              <InformationDialog isOpen={showInfo} onClose={setShowInfo} infoRef={infoRef} dialogData={dialogData} />
            </Container>
          )
        }
      </>}
      <Footer />
    </div>
  );
};

export default Nomination;
