import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Flex,
  Image,
  Link,
  IconButton,
  Text,
} from '@chakra-ui/react';
import { FiLogOut } from 'react-icons/fi';
import { useAuth0 } from '@auth0/auth0-react';

const Header = () => {
  const { isAuthenticated, logout } = useAuth0();
  const location = useLocation();

  const isActive = (pathname) => location.pathname === pathname;

  return (
    <Flex
      position="sticky"
      top="0"
      backgroundColor="white"
      zIndex="8"
      as="header"
      align="center"
      justify={{ base: 'space-between', md: "space-between" }}
      p="4"
      borderBottom="1px solid"
      borderColor="gray.200"
    >
      {/* Logo */}
      <RouterLink to="/">
        <Flex alignItems="center" gap="2" color="gray">
          <Image alt="gdg-pune-logo" src={require('../../assets/images/gdg-logo.png')} />
          <Text display={{ base: 'none', md: 'block' }}>Excellence Awards</Text>
        </Flex>
      </RouterLink>

      {
        isAuthenticated &&
        <IconButton display={{ base: 'block', md: 'none' }} icon={<FiLogOut />} aria-label="Logout" variant="ghost" color="gray.500" onClick={() => {
          logout(
            {
              returnTo: window.location.origin,
            }
          );
        }} />
      }

      {/* Navigation */}
      <Flex
        display={{ base: 'none', md: 'flex' }}
        align={{ base: 'center', md: 'center' }}
        mt={{ base: 4, md: 0 }}
        direction={{ base: 'column', md: 'row' }}
      >
        <Link
          as={RouterLink}
          to="/nominate"
          mr={{ base: 0, md: 4 }}
          mb={{ base: 2, md: 0 }}
          fontWeight={isActive('/nominate') ? 'bold' : 'normal'}
        >
          Nominate
        </Link>
        <Link
          as={RouterLink}
          to="/vote"
          mr={{ base: 0, md: 4 }}
          mb={{ base: 2, md: 0 }}
          fontWeight={isActive('/vote') ? 'bold' : 'normal'}
        >
          Vote
        </Link>
        <Link
          as={RouterLink}
          to="/results"
          fontWeight={isActive('/results') ? 'bold' : 'normal'}
        >
          Results
        </Link>
        {
          isAuthenticated &&
          <IconButton icon={<FiLogOut />} aria-label="Logout" variant="ghost" color="gray.500" onClick={() => {
            logout(
              {
                returnTo: window.location.origin,
              }
            );
          }} />
        }
      </Flex>

      {/* <Box display={{ base: 'block', md: 'none' }}>
        <IconButton
          icon={<FiMenu />}
          aria-label="Menu"
          variant="ghost"
          color="gray.500"
          onClick={toggleDrawer}
        />
      </Box>

      <Drawer isOpen={isDrawerOpen} onClose={toggleDrawer} placement="right">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menu</DrawerHeader>
          <DrawerBody>
            <Flex flexDirection='column'>
              <Link
                as={RouterLink}
                to="/vote"
                mb={2}
                fontWeight={isActive('/vote') ? 'bold' : 'normal'}
              >
                Vote
              </Link>
              <Link
                as={RouterLink}
                to="/nominate"
                mb={2}
                fontWeight={isActive('/nominate') ? 'bold' : 'normal'}
              >
                Nominate
              </Link>
              <Link
                as={RouterLink}
                to="/results"
                fontWeight={isActive('/results') ? 'bold' : 'normal'}
              >
                Results
              </Link>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer> */}
    </Flex>
  );
};

export default Header;
