import React from 'react'
import Header from '../shared/Header'
import Footer from '../shared/Footer'
import Banner from '../shared/Banner'
import { Box, Container, Text } from '@chakra-ui/react'

const TermsAndConditions = () => {
    return (
        <>
            <Header />
            <Banner title='Terms and Conditions' color='white' />
            <Container textAlign='left' minW='90vw' marginY={10}>
                <Text as='strong'>NO PURCHASE IS NECESSARY TO ENTER OR WIN. A PURCHASE OR PAYMENT OF ANYKIND WILL NOT INCREASE YOUR CHANCES OF WINNING.</Text>
                <Text my={2}>
                    PLEASE READ THESE OFFICIAL RULES CAREFULLY. BY ACCESSING OR PARTICIPATINGIN THE CONTEST, YOU <strong>(“Participant”, “you”, “your”)</strong> AGREE TO BE BOUND BY THESETERMS AND CONDITIONS AND ALL TERMS INCORPORATED BY REFERENCE. IF YOUDO NOT AGREE TO THESE TERMS, DO NOT PARTICIPATE IN THE CONTEST.
                </Text>
                <Text as='strong' my={2}>
                    DevFest Pune Award Portal: Terms and Conditions
                </Text>
                <br />
                <Text as='strong' my={2}>
                    1. Description:
                </Text>
                <Text>
                    The DevFest Pune Award Portal is an online platform designed to recognize and celebrateoutstanding individuals and entities in the technology and startup community. Through thisportal, participants can nominate candidates and cast votes for various award categories.
                </Text>
                <br />
                <Text as='strong' my={2}>
                    2. Eligibility:
                </Text>
                <Text>
                    Participants are required to adhere to the specific criteria outlined for each award category
                </Text>
                <br />
                <Text as='strong' my={2}>
                    3. Contest Period:
                </Text>
                <Text>
                    The contest commences on 20 December 2023 and concludes on 23 Decemeber 2023.
                </Text>
                <br />
                <Text as='strong' my={2}>
                    4. How to Enter the Contest:
                </Text>
                <Text>
                    Participants can enter the contest by submitting nominations through the official DevFest PuneAward Portal during the designated contest period.
                </Text>
                <br />
                <Text as='strong' my={2}>
                    5. Voting Period:
                </Text>
                <Text>
                    The voting period begins on 21 December 2023 and concludes on 23 December 2023. Each participant is granted one vote per award category.
                </Text>
                <br />
                <Text as='strong' my={2}>
                    6. Awards:
                </Text>
                <Text>
                    Winners will be determined based on a combination of public votes and an evaluation by apanel of judges. The decision of the public vote is considered final and binding.
                </Text>
                <br />
                <Text as='strong' my={2}>
                    7. Participation in Events:
                </Text>
                <Text>
                    While attendance at the DevFest Pune event for the award announcement is encouraged, it isnot mandatory for winning candidates.
                </Text>
                <br />
                <Text as='strong' my={2}>
                    8. Ownership and Confidentiality of Information and Data:
                </Text>
                <Text>
                    All information and data submitted through the DevFest Pune Award Portal are owned by theorganizers. Personal information will be treated with utmost confidentiality and will not be sharedwith third parties.
                </Text>
                <br />
                <Text as='strong' my={2}>
                    9. Communication and Dissemination:
                </Text>
                <Text>
                    Official communication regarding the contest, results, and relevant information will bedisseminated through official DevFest Pune channels. Participants may receive updates andnotifications related to the contest.
                </Text>
                <br />
                <Text as='strong' my={2}>
                    10. Knowledge of the Terms:
                </Text>
                <Text>
                    Participants are responsible for reading and comprehending these terms and conditions beforeparticipating in the contest.
                </Text>
                <br />
                <Text as='strong' my={2}>
                    11. Amendments:
                </Text>
                <Text>
                    DevFest Pune organizers reserve the right to make amendments to these terms and conditions.Any changes will be communicated through official channels.
                </Text>
                <br />
                <Text as='strong' my={2}>
                    12. Liability and Compensation:
                </Text>
                <Text>
                    DevFest Pune organizers bear no liability for any loss, damage, or injury resulting fromparticipation in the contest. Participants agree to indemnify and hold the organizers harmlessfrom any claims or liabilities.
                </Text>
                <br />
                <Text as='strong' my={2}>
                    13. Acts of God or Force Majeure:
                </Text>
                <Text>
                    DevFest Pune organizers are not responsible for any failure or delay caused by acts of God orforce majeure.
                </Text>
                <br />
                <Text as='strong' my={2}>
                    14. Interpretation of the Terms:
                </Text>
                <Text>
                    The interpretation of these terms and conditions lies solely with DevFest Pune organizers.
                </Text>
                <br />
                <Text as='strong' my={2}>
                    15. Costs, Taxes, Expenses:
                </Text>
                <Text>
                    Participants are responsible for any costs, taxes, or expenses incurred in connection with theirparticipation in the contest.
                </Text>
                <br />
                <Text as='strong' my={2}>
                    16. Errors and Authorized Activities:
                </Text>
                <Text>
                    DevFest Pune organizers are not responsible for errors, omissions, or unauthorized activitiesrelated to the contest.
                </Text>
                <br />
                <Text as='strong' my={2}>
                    17. Release:
                </Text>
                <Text>
                    Participants release DevFest Pune organizers from any claims or liabilities arising from theirparticipation in the contest.
                </Text>
                <br />
                <Text as='strong' my={2}>
                    18. Jurisdiction and Applicable Law:
                </Text>
                <Text>
                    These terms and conditions are governed by the laws of India. Any disputes will be subject tothe exclusive jurisdiction of the courts in Pune, Maharashtra.
                </Text>
                <br />
                By participating in the DevFest Pune Award Portal, you agree to abide by these terms and conditions.
            </Container>

            <Footer />
        </>
    )
}

export default TermsAndConditions