import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../components/home/Home';
import Nominate from '../components/nomination/Nomination';
import Vote from '../components/vote/Vote';
import Results from '../components/results/Results';
import Profile from '../components/vote/Profile';

import NotFound from '../components/shared/NotFound';
import VotingCategory from '../components/vote/VotingCategory';
import HandleCallback from './HandleCallback';
import TermsAndConditions from '../components/terms/TermsAndConditions';


const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Home/>} />
        <Route path="/callback" element={<HandleCallback/>}/>
        <Route path="/nominate" element={<Nominate/>} />
        <Route path="/vote" exact element={<Vote/>} />
        <Route path="/vote/:category" exact element={<VotingCategory />} />
        <Route path="/:category/:person" element={<Profile/>} />
        <Route path="/results" element={<Results/>} />
        <Route path="/t&c" element={<TermsAndConditions/>}/>
        <Route path='404' element={<NotFound />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
