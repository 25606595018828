import React from 'react'
import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react'
import Header from '../shared/Header'
import Footer from '../shared/Footer'
import VotingRowContainer from './VotingRowContainer'

const Vote = () => {

  return (
    <>
      <Header />
      <Box marginY={10} className='about'>
        <Flex justifyContent={{base: 'center', lg:'space-between'}} flexWrap={{base: 'wrap', md: 'nowrap'}} mb="4" marginX={{base: 10, md: 100}} alignItems='center'>
          <Flex direction='column' justifyContent='space-between' height={{base:'35vh',md:'45vh'}} textAlign='left' width={{base: 'fit-content', lg: '604px' }}>
            <Heading width='100%' as='h1' fontWeight='800' fontSize={{base:'35px', md:'48px'}} lineHeight={'145%'}>
              Vote for your favorite candidate, to make them WIN
            </Heading>
            <Text color='gray.500'>
            The DevFest'23 Pune Awards aim to honor the greatest solutions available now while also pushing the boundaries of what we think to be the critical components of a developer site for the future.
            </Text>
          </Flex>
          <Image src={require('../../assets/images/Jan-Success_1 1.png')} alt='about' />
        </Flex>
      </Box>
      <Box marginBottom={20}>       
        <Heading as='h2' size={{base:'sm', md: 'md'}} textAlign='center' mb='4'>
          Nominees
        </Heading> 
        <VotingRowContainer />
      </Box>
      <Footer />
    </>
  )
}

export default Vote