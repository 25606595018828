import React, { useEffect } from 'react';
import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import VotingCard from './VotingCard';
import axios from 'axios';

const VotingRow = ({ categoryId, awardCategory, shortcode, onViewAllClick }) => {
  const [nominees, setNominees] = React.useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/nomination/category`, {
          categoryId: categoryId
        });
        setNominees(data.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [shortcode]);

  return (
    <Box mb="4" marginX={{base: 10, md: 100}}>
      <Flex justifyContent="space-between" alignItems="center" mb="3">
        <Heading as="h2" size={{base:'sm', md: 'md'}}>
          {awardCategory}
        </Heading>
        <Button variant="outline" colorScheme='messenger' onClick={() => onViewAllClick(shortcode)}>
          View All
        </Button>
      </Flex>
      <Flex gap={10} flexWrap='nowrap' overflowX="auto">
        {nominees && nominees.length ? (
          nominees.map((nominee) => (
            <VotingCard
              key={nominee?.personData?.id}
              imageSrc={nominee?.personData?.photo}
              nomineeName={nominee?.personData?.firstName + " " + nominee?.personData?.lastName}
              designation={nominee?.personData?.designation}
              awardCategory={awardCategory}
              onVoteClick={nominee?.personData?.onVoteClick}
              shortcode={shortcode}
              nomineeShortCode={nominee?.personData?.shortcode}
              categoryId={categoryId}
              nominationId={nominee?.nomination?.id}
            />
          ))
        ): (
          <Text size="lg">
            No Nominees Found
          </Text>
        )}
      </Flex>
    </Box>
  );
};

export default VotingRow;