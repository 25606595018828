import { useAuth0 } from '@auth0/auth0-react';
import './App.css';
import Home from './components/home/Home';
import AppRoutes from './routes/AppRoutes';
import { ChakraProvider } from '@chakra-ui/react';

function App() {
  const {isLoading} = useAuth0();
  return (
    <div className="App" style={{width:"100%"}}>
      <ChakraProvider>
        {isLoading ?<></> :<AppRoutes />}
      </ChakraProvider>
    </div>
  );
}

export default App;
