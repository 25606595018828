import { AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from '@chakra-ui/react'
import React from 'react'

const InformationDialog = ({isOpen, onClose, infoRef, dialogData}) => {
  return (
    <AlertDialog
        motionPreset='slideInBottom'
        onClose={onClose}
        isOpen={isOpen}
        leastDestructiveRef={infoRef}
        isCentered
        size={{base:"sm",md:"md"}}
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>{dialogData.title}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            {dialogData.body}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button colorScheme='messenger' leastDestructiveRef={infoRef} onClick={()=>onClose(false)} ml={3}>
              Ok
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
    </AlertDialog>
  )
}

export default InformationDialog