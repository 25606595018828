// src/components/Banner.js
import React from 'react';
import { Box, Flex, Text, } from '@chakra-ui/react';
import mobileBanner from '../../assets/images/mobile-banner.jpg';
import desktopBanner from '../../assets/images/devfest-banner-yellow.png';

const Banner = ({ title, color }) => {
  return (
    <Flex
      width="100%"
      justify={{base: "flex-end", md: "center"}}
      direction="column"
      height={{base:"250px",md:"175px"}}
      // maxW="1440px"
      mx="auto"
      gap={{base: "20px", md: "0"}}
      position="relative"
    >
      <Box
        width="100%"
        height="100%"
        backgroundImage={{base: mobileBanner ,md: desktopBanner}}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        position="absolute"
        top="0"
        left="0"
        zIndex="-1"
        textAlign={{base: "center", md: "left"}}
      />
      <Text fontSize={['2xl', '3xl', '3xl', '4xl', '5xl']}
        fontWeight="bold"
        marginLeft={{base: "0", md: "80px"}}
        marginBottom={{base: "25px", md: "0"}}
        color={color}
        textAlign={{base: "center", md: "left"}}
        >{title}</Text>
    </Flex>
  );
};

export default Banner;
