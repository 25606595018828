import React from 'react';
import { Avatar, Box, Button, Flex, Heading, Image, Tag, TagLabel, Text, useToast } from '@chakra-ui/react';
import { categoryIcons, categoryColors } from '../../common/commonData';
import { useNavigate } from 'react-router-dom';
import VoterInfo from './VoterInfo';

const VotingCard = ({
    imageSrc,
    nomineeName,
    nomineeShortCode,
    designation,
    awardCategory,
    shortcode,
    onVoteClick,
    categoryId,
    nominationId
}) => {
    const toast = useToast();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = React.useState(false);
    const onClose = () => setIsOpen(false);
    const [hasAlreadyVoted, setHasAlreadyVoted] = React.useState(false);

    React.useEffect(() => {
        const hasVoted = JSON.parse(localStorage.getItem('votes'));
        if (hasVoted) {
            if (hasVoted.includes(categoryId)) {
                setHasAlreadyVoted(true);
            }
        }
    }, [categoryId]);

    const navigateToNominee = () => {
        navigate(`/${shortcode}/${nomineeShortCode}`);
    }

    return (
        <Box
            borderRadius="md"
            boxShadow="md"
            overflow="hidden"
            position="relative"
            minWidth="290px"
            minHeight="370px"
            maxWidth="290px"
            maxHeight="370px"
        >
            <VoterInfo categoryId={categoryId} nominationId={nominationId} isOpen={isOpen} onClose={onClose}/>
            <Box
                backgroundRepeat='no-repeat'
                backgroundImage={require('../../assets/images/card-bg.png')} position="relative"
                display='flex'
                justifyContent='center'
                alignItems='end'
                height="200px"
                textAlign="center"
            >
                <Image
                    src={imageSrc ? imageSrc : require('../../assets/images/placeholder.jpg')}
                    alt={nomineeName}
                    objectFit="cover"
                    objectPosition="bottom"
                    width="50%"
                    height="80%"
                    borderRadius="md"
                    borderWidth={1}
                    border={'solid white'}
                />
            </Box>

            <Flex alignItems='center' direction="column" p="4">
                <Heading as="h3" size="md" mb="2">
                    {nomineeName}
                </Heading>
                <Text fontSize="sm" color="gray.500" mb="2">
                    {designation}
                </Text>
                <Tag
                    variant='outline'
                    textAlign={'center'}
                    borderRadius='md'
                    w={'fit-content'}
                    color={categoryColors[awardCategory].color}
                    backgroundColor={categoryColors[awardCategory].bg}
                    borderWidth={2}
                    borderColor={categoryColors[awardCategory].color}
                    colorScheme={categoryColors[awardCategory].color}
                    fontSize="sm"
                    mb="2"
                >
                    <Avatar boxSize='12px' ml='1' mr='1' src={categoryIcons[shortcode]}  />
                    <TagLabel>{awardCategory}</TagLabel>
                </Tag>
                <Flex w='100%' justifyContent='center' gap={2}>
                    <Button w='40%' colorScheme="messenger" disabled={hasAlreadyVoted} onClick={()=>{
                        if (!hasAlreadyVoted) {
                            setIsOpen(true);
                        } else {
                            toast({
                                title: "You have already voted for this category.",
                                status: "error",
                                duration: 3000,
                                isClosable: true
                              })
                        }
                    }} mt="2">
                        Vote
                    </Button>
                    <Button w='40%' variant='outline' colorScheme="messenger" onClick={navigateToNominee} mt="2">
                        More Info
                    </Button>
                </Flex>
            </Flex>
        </Box>
    );
};

export default VotingCard;
