import React from 'react'
import Header from '../shared/Header'
import Footer from '../shared/Footer'
import { Container, Image } from '@chakra-ui/react'

const Results = () => {
  return (
    <>
      <Header />
        <Container marginY={20} maxW="container.xl" centerContent>
          <Image src={require('../../assets/images/coming-soon.png')} alt='coming soon' />
        </Container>
      <Footer /> 
    </>
  )
}

export default Results