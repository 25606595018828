import React, { useEffect } from "react";
import Header from "../shared/Header";
import Footer from "../shared/Footer";
import Banner from "../shared/Banner";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Flex, Input, InputGroup, InputLeftElement, Spinner } from "@chakra-ui/react";
import VotingCard from "./VotingCard";
import { FiSearch } from "react-icons/fi";
import axios from "axios";

const VotingCategory = () => {
    const { category } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(true);
    const [categoryData, setCategoryData] = React.useState(null);
    const [nominees, setNominees] = React.useState([]);
    const [search, setSearch] = React.useState("");

    useEffect(() => {
        async function fetchCategory() {
            try {
                const categoryData = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/category/shortcode`, {
                    shortcode: category
                });
                setCategoryData(categoryData.data);
                const nomineesData = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/nomination/category`, {
                    categoryId: categoryData.data.id
                });
                setNominees(nomineesData.data);
            } catch (error) {
                console.log(error);
            }
            setLoading(false);
        }
        fetchCategory();
    }, [category, navigate]);

    return (
        <>
            <Header />
            {loading ? (
                <Flex justifyContent="center" alignItems="center" height="90vh">
                    <Spinner size="xl" />
                </Flex>
            ) : (
                <>
                    <Banner title={categoryData.name} color="white" />
                    <Container minW={"90vw"} marginBottom={20} marginTop={5}>
                        <Flex justifyContent={{base:'center',md:'space-between'}} flexWrap='wrap' alignItems="center" mb={10}>
                            <h1 style={{ fontSize: "2rem" }}>Nominees</h1>
                            <InputGroup width='300px'>
                                <InputLeftElement
                                 children = {<FiSearch color="#0078FF" />}
                                 />
                                <Input placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                            </InputGroup>
                        </Flex>
                        <Flex justifyContent="center" minH='40vh' flexWrap='wrap' gap={10}  alignItems="center" mb={10}>
                            {
                                nominees.filter(
                                    (nominee) =>
                                        `${nominee.personData?.firstName} ${nominee.personData?.lastName}`.toLowerCase().includes(search.toLowerCase())
                                ).map((nominee) => {
                                    return (
                                        <VotingCard
                                            key={nominee.nomination?.id}
                                            nomineeName={nominee.personData?.firstName + " " + nominee.personData?.lastName}
                                            designation={nominee.personData?.designation}
                                            awardCategory={categoryData.name}
                                            imageSrc={nominee.personData?.photo}
                                            shortcode={categoryData.shortcode}
                                            nomineeShortCode={nominee.personData?.shortcode}
                                            categoryId={categoryData.id}
                                            nominationId={nominee.nomination?.id}
                                        />
                                    );
                                })
                            }
                            {
                                nominees.filter(
                                    (nominee) =>
                                        `${nominee.personData?.firstName} ${nominee.personData?.lastName}`.toLowerCase().includes(search.toLowerCase())
                                ).length === 0 && (
                                    <h1 style={{ fontSize: "2rem" }}>No Nominees Found</h1>
                                )
                            }
                        </Flex>
                    </Container>
                </>
            )}
            {!loading && <Footer />}
        </>
    );
};

export default VotingCategory;
